.cw-date-field {
  .mat-datepicker-toggle-default-icon {
    width: 1em;
  }
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: inherit;
  }
  .mat-mdc-form-field-infix {
    display: flex;
    min-height: 24px;
    padding: 0px !important;
  }
  .mdc-icon-button {
  padding: 0;
  height: 24px;
  width: 24px;
  }
  .mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
    padding-left: 0;
    padding-bottom: 1.25em;
  }
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
  .mat-mdc-form-field-focus-overlay {
    background-color: transparent;
  }
  .mat-focused {
    .mdc-line-ripple {
      &::before, &::after {
        border-width: 1px;
        border-color: #cccccc;
        transition: none !important;
      }
    }
  }
}
