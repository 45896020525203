.calendar-header {
  background-color: transparent;
  border: 1px solid #e7e7e7;
  border-bottom-width: 0px;
  border-radius: 5px 5px 0px 0px;
}

.calendar-header .btn:nth-child(1),
.calendar-header .btn:nth-child(3) {
  font-size: 36px;
}

.calendar-header .btn.disabled:nth-child(2) {
  pointer-events: none;
}

.calendar-header .btn.disabled:nth-child(2) p {
  color: #999;
}

.calendar-header .btn.disabled h2,
.calendar-header .btn.disabled h3 {
  color: #333;
  font-weight: bold;
  font-size: 20px;
}

.cal-month-view .cal-cell-row {
  background-color: inherit;
}

.cal-month-view .cal-cell-row:hover {
  background-color: transparent;
}

.cal-month-view .cal-days {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-top-width: 0px;
  border-color: #e7e7e7 !important;
  border-radius: 0px 0px 5px 5px;
}

.cal-month-view .cal-header {
  border: 1px solid #e7e7e7;
  border-bottom-width: 0px;
  font-weight: 500;

  .cal-cell {
    font-weight: 700;
  }
}

.cal-month-view .cal-day-cell,
.cal-month-view .cal-day-cell.cal-today {
  background-color: transparent;
  text-align: center;
  color: #3a4a66;
  min-height: 0;
}

.cal-month-view .cal-day-cell:not(.cal-weekend) {
  cursor: pointer;
}

.cal-month-view .cal-day-cell:hover {
  color: inherit;
}

.cal-month-view .cal-cell-row .cal-cell:hover {
  background-color: transparent;
}

.cal-month-view .cal-days .cal-cell-row:not(:last-child),
.cal-month-view .cal-day-cell,
.cal-month-view .cal-day-cell:not(:last-child) {
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cal-month-view .cal-cell-top {
  min-height: 60px;
  line-height: 42px;
  display: flex;
  align-items: center;
}

.cal-month-view .cal-day-cell .cal-day-number,
.cal-month-view .cal-day-cell.cal-today .cal-day-number {
  float: none;
  margin: 0px;
  line-height: 42px;
  font-size: 14px;
  font-weight: 500;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  border-radius: 10px;
}

.cal-month-view .cal-day-cell.cal-selected .cal-day-number,
.cal-month-view .cal-day-cell.cal-in-range .cal-day-number {
  background-color: rgb(235, 241, 251);
  color: #3a4a66;
  font-weight: 700;
  display: inline-block;
  width: 43px;
  height: 44px;
  border: 2px solid #3a4a66;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cal-month-view .cal-day-cell.cal-in-range .cal-day-number {
  background-color: #cbf17F;
  color: #97cc2e;
}

.cal-month-view .cal-day-cell.cal-out-month.cal-past .cal-day-number,
.cal-month-view .cal-day-cell.cal-out-month.cal-weekend .cal-day-number,
.cal-month-view .cal-day-cell.cal-out-month.cal-today .cal-day-number {
  color: #e7e7e7;

}

.cal-month-view .cal-day-cell.cal-past .cal-day-number,
.cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
  color: #e7e7e7;
}

.hidden-calendar.loading {
  min-height: 390px;
  border: 1px solid #e7e7e7;
  margin: 0;
  padding: 50px 0 0;
}

.btn.btn-secondary.ng-star-inserted,
.btn.btn-secondary.i,
.btn.btn-secondary.disabled.ng-star-inserted {
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  .calendar-header .btn:nth-child(2) .text-overflow-center {
    margin-left: -100%;
    margin-right: -100%;
    text-align: center;
  }

  .calendar-header .btn:nth-child(1) {
    text-align: center;
  }

  .calendar-header .btn:nth-child(3) {
    text-align: center;
  }
}

.compact-widget-wrapper {

  .cal-month-view .cal-day-cell,
  .cal-month-view .cal-day-cell.cal-today {
    color: var(--cbcw-font-color);
  }

  .cal-month-view .cal-header,
  .cal-month-view .cal-days {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .cal-month-view .cal-header .cal-cell {
    min-height: 60px;
    line-height: 42px;
  }

  .cal-month-view .cal-day-cell.cal-out-month.cal-past .cal-day-number,
  .cal-month-view .cal-day-cell.cal-out-month.cal-weekend .cal-day-number,
  .cal-month-view .cal-day-cell.cal-out-month.cal-today .cal-day-number,
  .cal-month-view .cal-day-cell.cal-past .cal-day-number,
  .cal-month-view .cal-day-cell.cal-weekend .cal-day-number {
    color: #D3D3D3;
  }

  .cal-month-view .cal-day-cell.cal-selected .cal-day-number,
  .cal-month-view .cal-day-cell.cal-in-range .cal-day-number {
    color: #FFFFFF;
    background-color: var(--cbcw-violet-color);
    border: 1px solid var(--cbcw-violet-color);
  }

  @media screen and (max-width:1024px) {
    .cal-month-view {
      .cal-day-cell:not(.cal-weekend) {
        cursor: default;
      }
    }
  }
}
