.has-success .form-control-feedback {
  color: #4caf50;
  opacity: 1;
}

.has-error .form-control-feedback {
  color: #ef6565;
  opacity: 1;
}


/*            Error handling               */

.form-control.ng-invalid.ng-touched {
  border: 1px solid #ef6565;
}

.widget-col {
  padding: 0;
}


/*  footer  */

.language-select {
  margin-bottom: 8px;
  margin-top: 2px;
}

.language-select img {
  width: 20px;
  cursor: pointer;
  margin: 0 3px;
}

.wizard-footer {
  margin-top: 10px;
  padding-top: 12px;
  padding-bottom: 10px;
}

@media(max-device-width: 768px) {
  .wizard-footer {
    text-align: center;

    .pull-right {
      float: none !important;
    }
  }
}

.wizard-footer .btn,
.wizard-footer .btn:active,
.reset-btn {
  border-radius: 0;
  border-width: 0px;
  color: #dedede;
}

.wizard-footer .btn-appointment-next,
.wizard-footer .btn-event-next,
.wizard-footer .btn-resource-next,
.wizard-footer .btn-appointment-finish,
.wizard-footer .btn-event-finish,
.wizard-footer .btn-resource-finish {
  margin-left: 4px;
  background-color: #3a4a66;
  border: 1px solid #3a4a66 !important;
  border-radius: 4px;
  color: #fff;
}

.btn-success:hover, .btn-success:active:focus {
  color: #fff;
  background-color: #3a4a66;
  border-color: #3a4a66;
  outline: none;
}

.btn-success:disabled, .btn-success:disabled:hover {
  color: #000;
  background-color: transparent;
  outline: none;
  border: 1px solid lightgray !important;
}

.reset-btn {
  background-color: rgb(238, 162, 54) !important;
  color: #101010 !important;
}

h4 {
  color: #3a4a66;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  margin: 30px auto;
}

.panel.panel-default {
  background-color: transparent;
  border-color: currentcolor currentcolor #e7e7e7;
  border-style: none none solid;
  border-width: medium medium 1px;
  box-shadow: none;
  color: #5f5f5f;
  font-family: "Open Sans", sans-serif;
  margin: 0;
  outline: medium none;
  padding: 0;
  text-decoration: none;
  /* height: 55px; */
}

.panel-default>.panel-heading {
  background-color: white !important;
  border-color: transparent;
  color: #5f5f5f;
  overflow: hidden;
  position: relative;
  padding: 0;
}

.panel-title {
  color: inherit;
  font-size: 12px;
  font-weight: 600;
  height: 55px;
  margin: 0;
  vertical-align: middle;
  width: 100%;
}

.panel-title>a {
  display: inline-block;
  height: 100%;
  width: 100%;
  outline: medium none;
  padding: 20px 15px;
  font-size: 14px;
}

.panel-title>a:hover,
.panel-title>a:focus {
  text-decoration: none;
}

// .arrow {
//   float: right;
// }

.summary {
  display: table;
}

.customer-data-msg {
  margin-bottom: 30px;
}

.customer-data-msg>h4 {
  margin-bottom: 5px;
  margin-top: 20px;
}

.contact-info .col-sm-6 {
  padding-left: 0;
}

.footer {
  position: relative;
  bottom: 20px;
  right: 0px;
  width: 100%;
  color: #FFFFFF;
  z-index: 4;
  text-align: right;
  margin-top: 60px;
  text-shadow: 0 0px 1px black;
}

@media (max-width: 768px) {
  .main .container {
    margin-bottom: 50px;
  }
  .nav-pills>li>a {
    text-align: center;
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .nav-pills>li.active>a,
  .nav-pills>li.active>a:hover,
  .nav-pills>li.active>a:focus,
  .nav-pills>li.valid:not(.active)>a,
  .nav-pills>li.valid:not(.active)>a:hover,
  .nav-pills>li.valid:not(.active)>a:focus,
  .nav-pills>li>a:hover,
  .nav-pills>li>a:focus {
    padding-bottom: 0px;
  }
  .nav-pills>li.valid:not(.active)>a:before {
    font-size: 16px;
    bottom: -4px;
  }
  .nav-pills>li>a i {
    font-size: 20px;
    padding: 10px 0;
  }
}

@media (min-width: 768px) {
  .navbar-form {
    margin-top: 21px;
    margin-bottom: 21px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .btn-wd {
    min-width: 140px;
  }
}

