.slots-group {
  .mat-mdc-tab-body-content {
    color: #3a4a66;
    font-family: Roboto,Helvetica Neue,sans-serif;
    padding: 0 !important;
    margin-top: 10px !important;
    .row {
      margin: 0 -5px !important;
      .slot-box, .slot-box-group {
        padding: 0 5px !important;
        margin-bottom: 10px !important;
      }
    }
  }
  .mdc-tab {
    min-width: 160px;
    letter-spacing: inherit;
  }
  .mat-mdc-tab:hover .mdc-tab__ripple::before {
    opacity: 0;
  }
  .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
    color: #00000082;
  }
  .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
    color: #3a4a66;
  }
  .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
    border-color: #3a4a66;
  }
  .mat-mdc-tab-header {
    border-bottom: 1px solid rgba(0,0,0,.12);
  }
}
.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab {
  flex-grow: 0;
}
